/* eslint-disable @typescript-eslint/naming-convention */
import { type SizeType } from 'antd/es/config-provider/SizeContext';

const viewportWidth = window.innerWidth;
const colors = {
  // primary: '#4F46E5',
  // yellow: '#FCC015',

  primary: '#274ECB',
  secondary: '#F5BF13',
  placeholder: '#808080',
  heading: '#5A4FCF',
  white: '#FFFFFF',
  black: '#000000',
  mutedPrimary: '#021E4C',
  mutedSecondary: '#BD9D64',
  mutedWhite: '#EBEBEB',
  mutedBlack: '#908C89',
  titleColor: '#858585',
  menuTitleColor: '#333333',
  searchIcon: '#808080',
  subheading: '#e6e6e6',
  mutedRed: '#ff4d4f',
  selectBorder: '#D9D9D9',
  siderMenuColor: '#3d5ccb',
};

const ConfigStyle = {
  theme: {
    components: {
      Button: {
        borderRadius: 0,
        colorPrimary: colors.primary,
        primaryColor: colors.white,
        colorPrimaryHover: colors.mutedPrimary,
      },
      Layout: {
        siderBg: '#FF0000',
        headerColor: colors.mutedBlack,
      },
      Table: {
        colorTextHeading: colors.black,
        // colorBgContainer: "#fff",
      },
      ...(viewportWidth > 900 && {
        Menu: {
          // subMenuItemBg: colors.white,
          // itemSelectedColor: colors.black,
          // itemSelectedBg: colors.secondary,
          // itemBg: colors.white,
          // itemColor: '#6c6666',
          // itemHoverColor: colors.black,
          // itemHoverBg: colors.secondary,

          subMenuItemBg: colors.white,
          itemSelectedColor: colors.white,
          itemSelectedBg: colors.siderMenuColor,
          itemBg: colors.mutedPrimary,
          itemColor: colors.titleColor,
          itemHoverColor: colors.white,
          itemHoverBg: colors.siderMenuColor,
        },
      }),
      // Card: {
      //   colorTextHeading: colors.heading,
      //   colorBgContainer: '#fff',
      //   colorPrimary: '#001E4C',
      // },
      // Form: {
      //   colorTextHeading: 'black',
      //   labelColor: 'black',
      // },
      Input: {
        colorTextPlaceholder: colors.mutedBlack,
      },
      Checkbox: {
        colorPrimary: colors.primary,
      },
      Select: {
        selectorBg: colors.white,
        colorBorder: colors.selectBorder,
        optionSelectedColor: colors.white,
        optionSelectedFontWeight: 800,
        colorBgElevated: colors.white,
        optionSelectedBg: colors.primary,
        optionActiveBg: colors.mutedWhite,
        optionFontSize: 16,
      },
      Radio: {
        buttonColor: colors.primary,
        buttonBg: colors.primary,
        buttonCheckedBg: colors.primary,
        colorPrimaryActive: colors.primary,
        buttonSolidCheckedColor: colors.primary,
        buttonSolidCheckedBg: colors.primary,
        buttonSolidCheckedActiveBg: colors.primary,
        // radioSize: 16,
        // dotSize: 8,
      },
      //   DatePicker: {
      //     colorTextPlaceholder: colors.placeholder,
      //   },
      //   Dropdown: {
      //     colorTextPlaceholder: colors.placeholder,
      //   },
      Typography: {
        colorTextHeading: colors.menuTitleColor,
        // fontFamily: 'Inter',
      },
      Tree: {},

      Segmented: {
        itemSelectedBg: colors.primary,
        itemSelectedColor: colors.white,
        trackBg: colors.subheading,
      },
      Collapse: {
        /* here is your component tokens */
        headerBg: '#d0ddf8',
      },
    },

    token: {
      fontFamily: `'Noto Sans','san-serif'`,
      fontSize: 16,
      colorBgBase: colors.white,
      colorBgContainer: colors.white,
      // colorPrimaryHover: colors.primary,
    },

    // h2: {
    //   fontWeightStrong: true,
    // },
  },

  componentSize: 'large' as SizeType,
  labelfontWeight: '600',
  labelColor: colors.black,
  colors,
};

export default ConfigStyle;
