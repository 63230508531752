/* eslint-disable import/extensions */
import { Card, Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import TypeAndQueryCard from '../TypeAndQueryCard';
import QueryBuilderCustomUtils from '../custom_utils/QueryBuilderCustomUtils';
import loadConfig from "../config";
const SingleMode = ({ value, fields, onSave, handleOnQueryChange }) => {
  const [singleObj, setSingleObj] = useState<any>({
    type: value.type,
    value: value.value,
    query: value.query,
    jsonLogic: value.jsonLogic,
  });
  const onTypeSave = (data) => {
    setSingleObj(data);
    handleOnQueryChange(data)
  };
  const onSaveHandler = () => {
    onSave({
      sql: singleObj.value,
      json: { ...singleObj },
      jsonLogic: singleObj.jsonLogic,
    });
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) setLoading(false);
  }, [loading]);
  useEffect(() => {
    const finalWhenData = { ...value };
    if (value?.caseList?.length === 0) {
      finalWhenData.caseList = [{ when: '', then: '' }];
    }
    setLoading(true);
  }, [value]);









  const showModal = () => {
    Modal.info({
      title: '',
      width: 1000,
      content: (
        <div>
          <pre>{singleObj.value}</pre>
        </div>
      ),
      onOk() { },
    });
  };

  const showJsonLogicModal = () => {
    const loadedConfig = loadConfig('antd', fields);
    debugger
    let jsonLogic = QueryBuilderCustomUtils.getJsonLogic({ ...singleObj }, loadedConfig);
    Modal.info({
      title: '',
      width: 1000,
      content: (
        <div>
          <pre>{JSON.stringify(jsonLogic, null, 2)}</pre>
        </div>
      ),
      onOk() { },
    });
  };


  console.log(singleObj, 'singleObj')








  return (
    <>
      <div className='flex justify-end single-option-query-btn-group query-btn-group'>
        <Button size='middle' type="default" className="mx-2" key="save" onClick={onSaveHandler}>
          Validate
        </Button>
        <Button size='middle' className="mx-2" type='default' onClick={showModal}>
          Show Query
        </Button>

        <Button size='middle' className="ml-2" type='default' onClick={showJsonLogicModal}>
          Show Json Query
        </Button>
      </div>

      <div>
        <TypeAndQueryCard
          title=""
          hideType={true}
          onSave={onTypeSave}
          data={singleObj}
          fields={fields}


        />
      </div>

    </>


  );
};

export default SingleMode;
